import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { chartTypeMap, GraphType } from 'src/app/model/generic-graph';
import { Subject } from 'rxjs';

const CANVAS_HEIGHT_PX = 350;
const PADDING_BOTTOM_CANVAS_PX = 10;
const PADDING_TOP_CANVAS_PX = 100;
const COMPUTED_CANVAS_HEIGHT_PX = CANVAS_HEIGHT_PX - PADDING_BOTTOM_CANVAS_PX - PADDING_TOP_CANVAS_PX;
const MINCHANGE_PX_OFFSET = 32;

@Component({
  selector: 'app-line-bar-graph',
  templateUrl: './line-bar-graph.component.html',
  styleUrls: ['./line-bar-graph.component.scss']
})
export class LineBarGraphComponent implements OnInit, OnChanges {

  constructor() {}
  @Input() lineGraph: any = {};
  @Input() selectedColumnIndex: number = 0
  private chartInstance: any;
  public options: EChartsOption = {};
  private _destroyed$ = new Subject();
  public currentPath: any = {};

  private arrayOfColors = [
    '#00BDDE',
    '#D1DCDD'
  ];

  private baseOptions: any = {
    xAxis: {
      type: 'category',
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: { show: false },
      splitLine: { show: false }
    },
    yAxis: {
      type: 'value',
      axisLine: { show: false },
      axisTick: { show: false },
      axisLabel: { show: false },
      splitLine: { show: false }
    },    
      grid: {
      left: '10%',  
      right: '14%', 
      top: '5%',    
      bottom: '5%', 
      containLabel: true,
    },
    series:null
  }

  ngOnInit(): void {
    this.initGraphOption();
  }
  ngOnDestroy(): void {
    this._destroyed$.next(true);
  }
  public onChartInit(ec: any): void {
    this.chartInstance = ec;
    this.initChartEvents(ec);
  }
  private mapCurrentData(graphData: any[]): any[] {
    return graphData.map((item, index, array) => this.mapCurrentDataItem(item, index, array));
  }
  private mapCurrentDataItem(item: any, index: any, array:any): any {
    const categoryName = item.colName;
    let labelPosition = 'top'; // Default position
    if (array.length === 1) {
      labelPosition = 'top';
    } else if (array.length > 1) {
      if (index === 0) {
        labelPosition = 'top';
      } else if (index === 1) {
        const currentValue = item.data[this.selectedColumnIndex];
        const firstDatasetValue = array[0].data[this.selectedColumnIndex];
        labelPosition = currentValue > firstDatasetValue ? 'top' : 'bottom';
      }
    }
  
    return {
      name: categoryName,
      type: 'line',
      lineStyle: {
        color:  this.arrayOfColors[index],
        width: 2,
        type: index === 0 ?'solid': 'dashed', // Makes the line dotted
        opacity:index === 0 ? 1 : 0.5
      },
      label: {
        show: true,
        formatter: (params: any) => {
          return params.dataIndex === this.selectedColumnIndex ? params.value : '';
        },
        fontSize: 14,
        fontFamily: 'DemoMedium',
        color:this.arrayOfColors[index],
        position: labelPosition,
      },
      symbol: 'circle',
      symbolSize: (value: any,params: any) => {
        return index === 0 && params.dataIndex === this.selectedColumnIndex ? 10 : 0;
      },
      itemStyle: {
        color:  this.arrayOfColors[index],
      },
      data: item.data,
      endLabel: {
        show: index === 0,
        formatter: '{a}',
        fontSize: 16,
        fontFamily: 'DemoMedium',
        color:'#4E738B'
      }
    };
  } 
  private initGraphOption(): void {
    if(this?.lineGraph?.data){
      this.baseOptions.series = [
        ...this.mapCurrentData(this.lineGraph.data)
      ];
    }
    this.options = { ...this.baseOptions };
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['lineGraph'] || changes['selectedColumnIndex']&& this.lineGraph) {
      this.initGraphOption();
    }
  }


  private initChartEvents(chartInstance: any): void {
  }
}
