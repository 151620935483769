import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { GraphView } from 'src/app/model/generic-graph';
import { NavigationOptions } from 'src/app/model/queryParams';
import { VerticalGraphType, VerticalGraphViewData } from 'src/app/model/vertical-views';
/**
 * @description
 * This component acts as a container for vertical bar graph and its corresponding battry graph.
 * It handles the connection between the two graphs, according to the currently selected bar.
 * If the current graph is not a main graph, the component will also be in charge of all its buisniess units' graphs and the navigation between them.
 */
@Component({
  selector: 'app-vertical-view-slide',
  templateUrl: './vertical-view-slide.component.html',
  styleUrls: ['./vertical-view-slide.component.scss']
})
export class VerticalViewSlideComponent {
  /**
   * @property {GraphView} data is an object of @see GraphView interface, containing a vertical bar graph and batteries' data
   * @property {NavigationOptions} navInfo Holds the navigation information from the current url path. Used for initializing deafult properties' values (such as business unit).
   * @property {Navigation} navState Navigation to current graph - containing section name (e.g. 'Sales', 'OI', 'EBITDA' etc.), current time tab, current slide, and current dropdown option (if exists)
   * @property {number} currentBUIndex Current buisness unit's index (for sub graphs only)
   */
  @Input() isTerritory!: boolean;
  @Input() data:GraphView = {} as GraphView;
  @Input() navInfo: NavigationOptions = {};
  @Input() navState: NavigationOptions = {};
  @Input() currentBUIndex: number = 0;
  @Input() isLoading: boolean = true;
  @Output() currentUserSelectedData = new EventEmitter<any>();
  
  @Input() isPercentage: any;
  @Input() isStackedArea: any;
  private currentDataValue: any;
  public selectedCountry: number = 5;
  public territoryObjectMap: string[] = ['northAmerica','southAmerica','europe','asia','restOfTheWorld','all']
  public selectedBatteryIndex = 0;

  @Input() set currentData(data: any) {
    if(data){
      this.currentDataValue = data;
      this.handleCurrentDataChange(data);
    }
  };

  get currentData(): any {
    return this.currentDataValue;
  }
  public changeSelectedCountry = (data:number)=>{
    this.selectedCountry = data
  }
  public changeBatteryIndex = (data:number)=>{
    this.selectedBatteryIndex = data
  }
  
  private handleCurrentDataChange(data: any, withReset = false): void {
    const graphData = this.isTerritory ? this.data.graph[this.territoryObjectMap[this.selectedCountry]]:this.data.graph;
    //case where user already selected data
    if(data && (data.colName || data.colNameStacked) ) {
      const currentGraphIndex = graphData.data[this.currentBUIndex].graph.data.findIndex(
        (item: any) => item.colName === data.colName
      );
      if(data.colNameStacked){
        const colData = this.data.graph.colData
        const indexOfValue = colData.findIndex((item:any)=> item.colName === data.colNameStacked)
        if(indexOfValue !== -1){
          this.onSelectGarphValue(indexOfValue);
        }else{
          const defaultIndex = colData.findIndex((item:any)=> item.isDefault === true)
          this.onSelectGarphValue(defaultIndex);
        }
      } else if(currentGraphIndex > -1) {
        if(this.currentSelectedColumnIndex !== currentGraphIndex) {
          this.onSelectGarphValue(currentGraphIndex);
        }
      } else {
        if(withReset) {
          const defaultIndex = graphData.data[this.currentBUIndex].graph.data.findIndex((item: any) => item.isDefault);
          if(defaultIndex > -1) {
            this.onSelectGarphValue(defaultIndex);
          } else {
            this.onSelectGarphValue(0);
          }
        }
      }
    }
    else {
      //case where no user action was taken in graph but data was updated
      const defaultIndex = graphData.data[this.currentBUIndex].graph.data.findIndex((item: any) => item.isDefault);
      if(this.currentSelectedColumnIndex !== defaultIndex) {
        this.onSelectGarphValue(defaultIndex);
      }
    }
  }


  public currentSelectedColumnIndex!: number;

  public indexUnit: number = 0;
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.initDefaultNavInfo();
  }

  ngOnChanges(changes : SimpleChanges){
    if(changes["currentBUIndex"]){
      this.indexUnit = changes["currentBUIndex"].currentValue;
    }
    if(changes["data"]){
      this.handleCurrentDataChange(this.currentDataValue, true);
    }
  }

  /**
   * Initialize bar index and selected business unit, if graph was directly navigated to through url
   */
  private initDefaultNavInfo(): void {
    const graphData = this.isTerritory ? this.data.graph[this.territoryObjectMap[this.selectedCountry]]:this.data.graph;
    if (this.navInfo.bu) {
      const index = graphData.data.findIndex((bu: VerticalGraphViewData) => bu.title === this.navInfo.bu);
      if (index > -1) {
        this.currentBUIndex = index;
      } else {
        //TODO: this needs defined behavior, currently will do nothing. this will pose an issue in future graphs where index 0 is disabled
        console.error(`selected business unit not allowed`);
      }
    }
    if (this.navInfo.column !== undefined && this.navInfo.column > -1 && this.navInfo.column < graphData.data[this.currentBUIndex].graph.data.length) {
      this.onSelectGarphValue(this.navInfo.column);
    }
    if(!this.currentSelectedColumnIndex && this.currentSelectedColumnIndex !== 0) {
        // no nav info - select default column for battery update
        const currentColumnIndex = graphData.data[this.currentBUIndex].graph.data.findIndex((item: any) => item.isDefault);
        if(currentColumnIndex > -1) {
          this.onSelectGarphValue(currentColumnIndex);
        } else {
          this.onSelectGarphValue(0);
        }
    }
  }

  public onSelectGarphValue(index: number): void {
    this.currentSelectedColumnIndex = index;
    this.cdr.detectChanges();
  }

  public onUserBarSelect(columnData: any): void {
    this.currentUserSelectedData.emit(columnData);
  }

  // public onChangeUnit(unitIndex: number): void {
  //   this.cdr.detectChanges();
  // }

  public getBarType(data: GraphView): VerticalGraphType {
    // if(data.title === "LY" || data.title === "BGT") {
    //   return VerticalGraphType.COMPARE_BAR;
    // } else {
    //   return VerticalGraphType.SIMPLE_BAR;
    // }
    return VerticalGraphType.SIMPLE_BAR;
  }
}
