export const environment = {
  production: false,
  useApi: true,
  baseUri: 'https://api.vibes2-qa.apps4.icldig.icl-group.com/',
  dataApiEndpoint: 'getPaperChart',
  stocksAPIUrl: 'globes-api/getAllPapers',
  histogramStockAPIUrl: `globes-api/getPaperChart?paperid={{paperId}}`,
  adalConfig: {
    clientId: 'abac6ec3-b08c-40fa-835b-bc1529669010',
    issuer: 'https://login.microsoftonline.com/802762d2-02c4-4677-98ba-54060a234204/v2.0',
    strictDiscoveryDocumentValidation: false,
    responseType: 'id_token token',
    scope: 'abac6ec3-b08c-40fa-835b-bc1529669010/.default',
  },
  version: '3.0.4',
};
