<ng-container *ngIf="businessUnits && businessUnits.length > 0  && !isDividedNavigation">
    <div (scroll)="onScroll($event)" class="buisness-units-tabs" [ngClass]="{'sbu-over-one': subBusinessUnits.length < 2 && type!=='TERRITORY'}" #businessUnitsDiv >
        <button *ngFor="let unit of businessUnits; let index = index"
        class="unit-tab" [ngClass]="{'active': index === +currentUnitIndex, 'unit-tab-center': type==='TERRITORY'}"
        (click)="changeUnit(index)">
            <div class="unit-tab-titles" [ngClass]="{'sub-unit-tab-titles': unit.subtitle}">
                <span class="subtitle" *ngIf="unit.subtitle">{{unit.subtitle}}</span>
                <span class="title" [ngClass]="{'sub-buisness-unit' : unit.subtitle}">{{unit.title}}</span>
            </div>
        </button>
    </div>
    <div class="buisness-units-tabs" *ngIf="subBusinessUnits.length > 1">
        <button *ngFor="let subUnit of subBusinessUnits; let index = index"
            class="unit-tab sub-unit-tab" [ngClass]="{'active': index === +currentSubUnitIndex}"
            (click)="changeSubUnit(index)" >
            <span *ngIf="subBusinessUnits.length > 1">{{subUnit.title}}</span>
        </button>
    </div>
</ng-container>
<ng-container *ngIf="isDividedNavigation && businessUnits.length > 0">
    <div class="buisness-units-tabs-divided" #businessUnitsDiv >
        <div  class="unit-tab mainTab"  [ngClass]="{'active': isPrimaryMenuOpen}" (click)="toggleMenu(true)">
            <div class="unit-tab-titles ">
                <span class="title">{{dividedBuisnessUnitsData[selectedMainCategory].title}}</span>        
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9L12 15L18 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>                  
            </div>
            <div class="subMenu" [ngClass]="{'hideMenu': !isPrimaryMenuOpen}">
                <button *ngFor="let item of dividedBuisnessUnitsData; let index = index" class="unit-tab" (click)="changeUnitDivided(index,true)" [ngClass]="{'active': index === selectedMainCategory}" >
                    <div class="unit-tab-titles subMenuItem">
                        <span class="title" >{{item.title}}</span>
                    </div>
                </button>
            </div>
        </div>
        <div  class="unit-tab mainTab" [ngClass]="{'active': isSecondaryMenuOpen}" (click)="toggleMenu(false)">
            <div class="unit-tab-titles">
                <span class="title">{{dividedBuisnessUnitsData[selectedMainCategory].submenu[selectedSecondaryCategory]}}</span>
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 9L12 15L18 9" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>     
            </div>
            <div class="subMenu" [ngClass]="{'hideMenu': !isSecondaryMenuOpen}">
                <button *ngFor="let item of dividedBuisnessUnitsData[selectedMainCategory].submenu; let index = index" class="unit-tab" (click)="changeUnitDivided(index,false)" [ngClass]="{'active': index === selectedSecondaryCategory}">
                    <div class="unit-tab-titles subMenuItem">
                        <span class="title" >{{item}}</span>
                    </div>
                </button>
            </div>
        </div>
    </div>
</ng-container>