<div class="section-header" #header>
  <div
    class="section-header-inner"
    *ngIf="sectionTitle && hasDropdown && !hideHeader"
  >
    <h2 class="section-title">
      {{ dataForChart.type !== graphTypes.SBU ? sectionTitle : "" }}
    </h2>
  </div>

  <div class="section-header-inner">
    <h2
      class="section-title"
      *ngIf="sectionTitle && !hasDropdown && !isCategoryNav"
    >
      {{ dataForChart.type !== graphTypes.SBU ? sectionTitle : "" }}
    </h2>

    <div *ngIf="hasDropdown" class="dropdown-oi-container">
      <button (click)="toggleDropdown()" class="arrow">
        <span>{{
          dataForChart.type === graphTypes.SBU
            ? dropdownOption[currentDropdownIndex] + " by SBU"
            : (hideHeader ? "" : "by ") + dropdownOption[currentDropdownIndex]
        }}</span>
        <img
          [ngClass]="{ 'is-dropdown-open': isDropdownOpen }"
          src="assets/icons/chevron.svg"
          alt="toggle dropdown options button"
        />
      </button>
      <div *ngIf="isDropdownOpen" class="buttons-dropdwon">
        <button
          *ngFor="let btnTitle of dropdownOption; let i = index"
          (click)="changeDataByDropdown(i, btnTitle)"
          [ngClass]="{ 'selected-option': currentDropdownIndex === i }"
          class="option-btn"
        >
          <img
            *ngIf="currentDropdownIndex === i"
            src="assets/images/checked.png"
            alt="checked option"
            class="checked-icon"
          />
          <span>{{ btnTitle }}</span>
        </button>
      </div>
    </div>
    <div *ngIf="isCategoryNav" class="dropdown-oi-container">
      <button (click)="toggleCategoryNav()" class="arrow">
        <span>{{
          dataForChart.type === graphTypes.SBU
            ? dropdownOption[currentDropdownIndex] + " by SBU"
            : (hideHeader ? "" : "by ") + dropdownOption[currentDropdownIndex]
        }}</span>
        <img
          [ngClass]="{ 'is-dropdown-open': isCategoryNavOpen }"
          src="assets/icons/chevron.svg"
          alt="toggle dropdown options button"
        />
      </button>
      <div
        class="buttons-drawer"
        [ngClass]="{ 'buttons-drawer-open': isCategoryNavOpen }"
      >
        <div class="drawer-search-container">
          <div class="drawer-block-line"></div>
          <div class="drawer-text-header" style="font-weight: bold">
            Select Category
          </div>
          <div class="search-line-container">
            <span class="beforeSearch">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clip-path="url(#clip0_5416_9826)">
                  <path
                    d="M3 7.17857C3 8.2868 3.44024 9.34963 4.22387 10.1333C5.00751 10.9169 6.07035 11.3571 7.17857 11.3571C8.2868 11.3571 9.34963 10.9169 10.1333 10.1333C10.9169 9.34963 11.3571 8.2868 11.3571 7.17857C11.3571 6.07035 10.9169 5.00751 10.1333 4.22387C9.34963 3.44024 8.2868 3 7.17857 3C6.07035 3 5.00751 3.44024 4.22387 4.22387C3.44024 5.00751 3 6.07035 3 7.17857Z"
                    stroke="#4E738B"
                    stroke-width="0.964286"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.3931 10.0718L12.9645 12.6432"
                    stroke="#4E738B"
                    stroke-width="0.964286"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5416_9826">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <input
              type="text"
              class="drawer-search"
              #searchInput
              (input)="filterDrawerMenuByString($event)"
            />
            <span class="afterSearch" (click)="clearSearch()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <g clip-path="url(#clip0_5416_9831)">
                  <path
                    d="M11.2998 4.7002L4.70016 11.2999"
                    stroke="#99B0BC"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M4.70018 4.7002L11.2998 11.2999"
                    stroke="#99B0BC"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_5416_9831">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </div>
        </div>
        <div class="drawer-categories-container">
          <div
            *ngFor="let categoryData of filteredDrawerMenuData"
            class="drawer-category-container"
          >
            <div class="drawer-text-header">{{ categoryData.name }}</div>
            <div
              *ngFor="let button of categoryData.data"
              (click)="changeDataByDrawer(button.index, button.name)"
              class="drawer-button"
              [ngClass]="{
                'drawer-button-selected': currentDropdownIndex === button.index
              }"
            >
              <span class="drawer-text-p">{{ button.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="timeTabs.length > 0" class="time-tabs" role="tablist">
      <button
        *ngFor="let tab of timeTabs; let index = index"
        type="button"
        role="tab"
        [attr.aria-selected]="index === currentTab"
        [attr.aria-controls]="sectionTitle + '_tabpanel'"
        [id]="tab.key"
        [tabindex]="index === currentTab ? 0 : -1"
        class="tab"
        [ngClass]="{ active: index === currentTab }"
        (click)="changeTab(index, true)"
        [disabled]="tab.disabled"
      >
        {{ tab.name }}
      </button>
    </div>
  </div>
</div>

<div
  class="section-content"
  [id]="sectionTitle + '_tabpanel'"
  role="timeTabs.length > 0 ? 'tabpanel' : ''"
  [attr.aria-labelledby]="timeTabs.length > 0 && timeTabs[currentTab].key"
>
  <app-business-unit-navigation
    [sectionTitle]="sectionTitle"
    [currentUnitIndex]="currentBUIndex"
    (currentUnitIndexChange)="onChangeUnit($event)"
    (currentSubUnitIndexChange)="onChangeSubUnit($event)"
    [(currentSubUnitIndex)]="currentSBUIndex"
    [businessUnits]="getBusinessUnits()"
    [subBusinessUnits]="getSubBusinessUnits()"
    [timeTabs]="timeTabs"
    [currentTimeTab]="currentTab"
    [dropdownOption]="dropdownOption"
    [currentDropdownIndex]="currentDropdownIndex"
    [currentSlide]="this.currentSlide"
    [currentPath]="this.currentPath"
    [type]="this?.dataForChart?.type"
    [isDividedNavigation]="this?.dataForChart?.isDividedNavigation"
  >
  </app-business-unit-navigation>
  <!-- <ng-container *ngFor="let navItem of navData; let index = index">
    <ng-container *ngIf="currentTab === index && navItem && navItem.length > 0"> -->
  <ng-container>
    <div *ngIf="hasButtonNavigation">
      <div class="units">
        <ng-container *ngFor="let btnTitle of dropdownOption; let i = index">
          <button
            class="unit-button"
            (click)="changeDataByDropdown(i, btnTitle)"
            [ngClass]="{
              selected: currentDropdownIndex === i
            }"
          >
            <span class="title">{{ btnTitle }}</span>
          </button>
        </ng-container>
      </div>
    </div>
    <ng-container>
      <ngx-slick-carousel
        class="carousel"
        #slickModal="slick-carousel"
        [config]="sliderCountHandle(navData[currentTab].length)"
        (init)="onSlickInit($event)"
        (afterChange)="afterChange($event)"
      >
        <div
          *ngFor="
            let slide of navData[currentTab];
            let index = index;
            trackBy: trackByFunc
          "
          ngxSlickItem
          class="slider"
        >
          <ng-container
            *ngTemplateOutlet="
              itemTemplate;
              context: {
                data: slide,
                navInfo: navInfo.slide === index ? navInfo : {},
                sectionNavState: getCurrentNavState(),
                componentState: componentState,
                setComponentState: setComponentState.bind(this)
              }
            "
          ></ng-container>
        </div>
      </ngx-slick-carousel>
    </ng-container>
  </ng-container>
</div>
