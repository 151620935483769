import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Subject, takeUntil, take } from 'rxjs';
import {
  GraphContainer,
  NavigationType,
  GraphNavigation,
  TimePeriods,
  GraphMenu,
  GraphType,
  chartTypeMap,
} from 'src/app/model/generic-graph';
import {
  NavigationItem,
  RTSparkChart,
  SubNavigationItem,
} from 'src/app/model/production-views';
import { NavigationOptions } from 'src/app/model/queryParams';
import { DataService } from 'src/app/services/data.service';

/**
 * @description
 * This component acts as the container for a division's graph (e.g. 'Sales', 'Net Income').
 * It displays the graph's data according to a template that is provided,
 * and manages time tabs navigation and slider.
 */

// Enum for intersecting with view screen
export enum IntersectionState {
  FirstTimeEntering,
  Entering,
  Inside,
  Leaving,
  BeforeEnter,
}

@Component({
  selector: 'app-section-layout',
  templateUrl: './section-layout.component.html',
  styleUrls: ['./section-layout.component.scss'],
})
export class SectionLayoutComponent implements OnInit, OnDestroy {
  private inactivityTimeout: any;
  private chartExposureTimer: any;
  private lastUserActivity: number = Date.now();

  private initialRun = true;
  private tempIntersectionState = IntersectionState.BeforeEnter;

  // Bound function for resetting inactivity timer
  private resetInactivityTimerBound: () => void;
  // Bound function for visibility change
  private boundHandleVisibilityChange: () => void;

  // Called when the observed element enters/leaves the viewport
  private handleEnterState(isIntersecting: boolean) {
    if (isIntersecting) {
      if (this.initialRun) {
        this.onIntersection.emit(IntersectionState.FirstTimeEntering);
        this.initialRun = false;
      }
      this.onIntersection.emit(IntersectionState.Entering);
      this.tempIntersectionState = IntersectionState.Entering;
      setTimeout(() => {
        if (this.tempIntersectionState === IntersectionState.Entering) {
          this.onIntersection.emit(IntersectionState.Inside);
          this.tempIntersectionState = IntersectionState.Inside;
          this.dataService.setGraph(this.sectionTitle as string, false);
          // Start or restart analytics when fully visible.
          this.handleChartExposureAnalytics(
            this.sectionTitle,
            this.currentPath.div
          );
        }
      }, 500);
    } else {
      this.onIntersection.emit(IntersectionState.Leaving);
      this.tempIntersectionState = IntersectionState.Leaving;
    }
  }

  private handleChartExposureAnalytics(graph: any, div: any) {
    // Prevent multiple timers from running at once.
    if (this.chartExposureTimer) {
      return;
    }
    
    this.isChartExposureAnalyticsRunning = true;
    let firstRun = true;
    let fib1 = 1; // will be used as the delay for the next event (first delay = 1 second)
    let fib2 = 2; // the next Fibonacci number
    
    const sendEventAtFibInterval = () => {
      if (this?.currentPath?.graph === graph && this?.currentPath?.div === div) {
        if (firstRun) {
          this.sendEvent(2, true);
          firstRun = false;
          this.chartExposureTimer = setTimeout(sendEventAtFibInterval, fib1 * 1000);
        } else {
          const currentDelay = fib1; 
          this.sendEvent(currentDelay, false);
          const newFib = fib1 + fib2;
          fib1 = fib2;
          fib2 = newFib;
          
          this.chartExposureTimer = setTimeout(sendEventAtFibInterval, fib1 * 1000);
        }
      } else {
        if (this.chartExposureTimer) {
          clearTimeout(this.chartExposureTimer);
          this.chartExposureTimer = null;
        }
        this.isChartExposureAnalyticsRunning = false;
      }
    };
      this.chartExposureTimer = setTimeout(sendEventAtFibInterval, 2000);
  }

  private sendEvent(delta: number, firstRun: boolean) {
    const currentSlideMap = ['last year', 'no comparasion', 'budget'];
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'fireChartInteraction',
      event_name: firstRun ? 'chart_exposure' : 'chart_timing',
      division: this?.currentPath?.div || 'ICL',
      chart_name: this?.currentPath?.graph || 'StocksPanel',
      type: 'impression',
      section: 'screen content',
      chart_type: chartTypeMap.get(this?.dataForChart?.type ?? GraphType.Sales),
      chart_state:
        this?.dropdownOption?.[this?.currentDropdownIndex] || undefined,
      time_range: this?.timeTabs?.[this?.currentTab || 0]?.name || undefined,
      comparison_scope:
        this?.currentSlide !== undefined
          ? currentSlideMap?.[this?.currentSlide]
          : undefined,
      sub_business_unit:
        this?.dataForChart?.subMenu?.[this.currentBUIndex]?.title || undefined,
      chart_timing: firstRun ? 2 : delta,
      chart_exposure: firstRun ? 1 : undefined,
    });
  }

  private intersectionObserver = new IntersectionObserver(
    (entries) => {
      const entry = entries[0];
      this.handleEnterState(entry.isIntersecting);
    },
    { threshold: [0.55, 0.7, 1] }
  );

  @Input() dataForChart!: GraphContainer;
  @Input() itemTemplate!: TemplateRef<any>;
  @Input() itemTemplateTest!: TemplateRef<any>;

  @Output() onIntersection: EventEmitter<IntersectionState> =
    new EventEmitter<IntersectionState>();
  @Output() updateNavState: EventEmitter<Object> = new EventEmitter<Object>();

  @ViewChild('header') headerRef!: ElementRef;
  @ViewChild('searchInput') searchInput!: ElementRef;

  public componentState: any;
  public setComponentState(state: any) {
    this.componentState = state;
    this.cdr.detectChanges();
  }

  private slick: any;
  private _destroyed$ = new Subject();

  /**
   * @property navData contains an array with graphs' data for each time tab.
   * @property navInfo contains the current navigation state from the URL.
   * @property timeTabs is an array of tabs representing time periods.
   */
  public navData: any[] = [];
  public navInfo: NavigationOptions = {};
  public sectionTitle: String = '';
  public hideHeader: boolean = false;
  public graphTypes: any = GraphType;
  public navigationTypes: any = NavigationType;

  public hasDropdown: boolean = false;
  public hasButtonNavigation: boolean = false;
  public isCategoryNav: boolean = false;
  public isDropdownOpen: boolean = false;
  public isCategoryNavOpen: boolean = false;
  public drawerMenuData: any = []
  public filteredDrawerMenuData: any = []
  public stringToFilterBy :string = ""
  public currentDropdownIndex: number = 0;
  public dropdownOption: String[] = [];
  private isChartExposureAnalyticsRunning: boolean = false;

  public currentTab: number = 0;
  public timeTabs: { key: string; name: string; disabled: boolean }[] = [];

  public currentSlide: number | undefined;
  public sliderConfig: any = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    dots: true,
    initialSlide: 0,
  };
  public currentPath: any = {};

  public currentBUIndex: number = 0;
  public currentSBUIndex: number = 0;

  constructor(
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private nativeElementRef: ElementRef
  ) {
    // Bind the reset inactivity function so we can remove the listener later.
    this.resetInactivityTimerBound = this.resetInactivityTimer.bind(this);
    // Bind the visibility change handler.
    this.boundHandleVisibilityChange = this.handleVisibilityChange.bind(this);
  }

  ngOnInit(): void {
    this.setupInactivityTracking();
    this.setupVisibilityChangeListener(); // Listen for visibility changes
    this.dataService.currentPath
      .pipe(takeUntil(this._destroyed$))
      .subscribe((params) => {
        this.handleNavigationChange(params);
        this.currentPath = params;
      });
    this.hideHeader =
      this.dataForChart.type === 'GROSS' ||
      this.dataForChart.type === 'RFC' ||
      this.dataForChart.type === 'TERRITORY' ||
      this.dataForChart.type === 'SALES_PERCENT'||
      this.dataForChart.type === 'POTASH_AVG';
    this.sectionTitle = this.dataForChart.title;
    this.sliderConfig.customPaging = (slider: any, index: number): string => {
      return this.customNav(slider, index);
    };
    this.initCurrentData();
    this.initNavInfo();
  }

  // Set up listeners for user activity and start the inactivity timer.
  private setupInactivityTracking(): void {
    window.addEventListener('mousemove', this.resetInactivityTimerBound);
    window.addEventListener('touchstart', this.resetInactivityTimerBound);
    window.addEventListener('scroll', this.resetInactivityTimerBound);

    this.inactivityTimeout = setTimeout(() => {
      this.handleInactivity();
    }, 60000);
  }

  // Reset the inactivity timer on user interaction.
  private resetInactivityTimer = () => {
    this.lastUserActivity = Date.now();
    clearTimeout(this.inactivityTimeout);
    this.inactivityTimeout = setTimeout(() => {
      this.handleInactivity();
    }, 60000);

    // If analytics are not running, the component is in view, and the document is visible,
    // restart the analytics tracking.
    if (
      !this.isChartExposureAnalyticsRunning &&
      this.tempIntersectionState === IntersectionState.Inside &&
      !document.hidden
    ) {
      this.handleChartExposureAnalytics(
        this.sectionTitle,
        this.currentPath.div
      );
    }
  };

  // Set up the listener for document visibility changes.
  private setupVisibilityChangeListener(): void {
    document.addEventListener(
      'visibilitychange',
      this.boundHandleVisibilityChange
    );
  }

  // Handle visibility changes.
  private handleVisibilityChange(): void {
    if (document.hidden) {
      this.handleInactivity(); // Cancel analytics tracking when hidden.
    } else {
      this.setupInactivityTracking(); // Resume tracking user activity.
      // Restart analytics if the component is in view and not already running.
      if (
        this.tempIntersectionState === IntersectionState.Inside &&
        !this.isChartExposureAnalyticsRunning
      ) {
        this.handleChartExposureAnalytics(
          this.sectionTitle,
          this.currentPath.div
        );
      }
    }
  }

  // Cancel the analytics timer if inactivity is detected.
  private handleInactivity(): void {
    if (this.isChartExposureAnalyticsRunning) {
      if (this.chartExposureTimer) {
        clearTimeout(this.chartExposureTimer);
        this.chartExposureTimer = null;
      }
      this.isChartExposureAnalyticsRunning = false;
    }
  }  

  private handleNavigationChange(params: NavigationOptions): void {
    if (
      params.graph !== this.sectionTitle &&
      this.isChartExposureAnalyticsRunning
    ) {
      if (this.chartExposureTimer) {
        clearTimeout(this.chartExposureTimer);
        this.chartExposureTimer = null;
      }
      this.isChartExposureAnalyticsRunning = false;
    }
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    setTimeout(() => {
      this.scrollToGraph();
    });
    this.intersectionObserver.observe(this.nativeElementRef.nativeElement);
  }

  private cleanupTimers(): void {
    if (this.inactivityTimeout) {
      clearTimeout(this.inactivityTimeout);
    }
    if (this.chartExposureTimer) {
      clearTimeout(this.chartExposureTimer);
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next(true);
    this.cleanupTimers();
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
    window.removeEventListener('mousemove', this.resetInactivityTimerBound);
    window.removeEventListener('touchstart', this.resetInactivityTimerBound);
    window.removeEventListener('scroll', this.resetInactivityTimerBound);
    document.removeEventListener(
      'visibilitychange',
      this.boundHandleVisibilityChange
    );
  }

  /**
   * This function sets the default navigation parameters (time tab, slide, dropdown) according to the current URL path.
   */
  private initNavInfo(): void {
    this.dataService.currentPath
      .pipe(take(1))
      .subscribe((params: NavigationOptions) => {
        if (params.graph && params.graph === this.sectionTitle) {
          if (params.dropdown) {
            let index = this.dropdownOption.findIndex(
              (option) => option === params.dropdown
            );
            if (index > -1 && index < this.dropdownOption.length) {
              this.currentDropdownIndex = index;
            }
          }
          if (params.nav) {
            this.changeTab(+params.nav);
          }

          if (params.slide !== undefined) {
            const slideIndex = +params.slide;
            if (
              slideIndex > -1 &&
              slideIndex < this.navData[this.currentTab].length
            ) {
              this.currentSlide = slideIndex;
            }
          } else {
            if (this.navData[this.currentTab].length === 3) {
              this.currentSlide = 1;
            } else {
              this.currentSlide = 0;
            }
          }
          if (this.dataForChart.subMenu) {
            let shouldEmit = false;
            const timeTabSubMenu = this.dataForChart.subMenu;
            if (params.bu) {
              const index = timeTabSubMenu.findIndex((el) => {
                if (params.buSubtitle) {
                  return (
                    params.buSubtitle?.toLowerCase() ===
                      el.subtitle?.toLowerCase() && params.bu === el.title
                  );
                } else {
                  return params.bu === el.title;
                }
              });
              if (index > -1) {
                this.currentBUIndex = index;
                shouldEmit = true;
              }
            }
            if (params.sbu) {
              const index = (
                timeTabSubMenu[this.currentBUIndex].data ?? []
              ).findIndex((el) => el.title === params.sbu);
              if (index > -1) {
                this.currentSBUIndex = index;
                shouldEmit = true;
              }
            }
            if (shouldEmit) {
              this.emitNavState();
            }
          }

          this.navInfo = {
            ...params,
            nav: this.currentTab,
            slide: this.currentSlide ?? 0,
            dropdown: this.dropdownOption[this.currentDropdownIndex],
            bu: this.currentBUIndex,
            sbu: this.currentSBUIndex,
          };
        }
      });
  }

  public onSlickInit(event: any) {
    this.slick = event.slick;
  }

  /**
   * Scrolls view to the selected graph.
   */
  private scrollToGraph(): void {
    this.dataService.currentPath
      .pipe(takeUntil(this._destroyed$))
      .subscribe((params) => {
        if (
          params.graph &&
          params.graph === this.sectionTitle &&
          this.tempIntersectionState !== IntersectionState.Inside
        ) {
          setTimeout(() => {
            this.headerRef.nativeElement.scrollIntoView(true);
          });
        }
      });
  }

  /**
   * Returns the current navigation state of this graph's section.
   */
  public getCurrentNavState(): NavigationOptions {
    return {
      graph: this.sectionTitle,
      nav: this.currentTab,
      slide: this.slick ? this.slick.currentSlide : this.currentSlide,
      dropdown: this.dropdownOption[this.currentDropdownIndex],
      bu: this.currentBUIndex,
      sbu: this.currentSBUIndex,
    };
  }

  /**
   * Creates a custom dot for slide navigation.
   */
  private customNav(slider: any, index: number): string {
    let navName = this.navData[this.currentTab][index].title;
    return `<button class="paginator"></button> <span class="paginator-label">${navName}</span>`;
  }

  /**
   * Extracts the graph's data according to its type and initializes navData with an array for each time tab.
   */
  private initCurrentData(): void {
    let nav = this.dataForChart.navigation.nav;
    switch (this.dataForChart.navigation.type) {
      case NavigationType.GraphNavigation:
        nav = nav as GraphNavigation;
        this.createTimeTabs(nav);
        this.setDefaultTimeTabIndex();
        this.navData = [nav.month, nav.qtd, nav.ytd];
        break;
      case NavigationType.GraphViews:
        this.navData = [nav];
        break;
      case NavigationType.GraphMenu:
        nav = nav as GraphMenu;
        this.initDropdownData(nav);
        if(this.dataForChart.navigation.isCategoryNav){
          this.isCategoryNav = true;
          this.drawerMenuData = this.generateDrawerMenu(nav)
          this.filteredDrawerMenuData= this.drawerMenuData;
        }else{
          if(this?.dataForChart?.type !== 'RFC'){
            this.hasDropdown = true
            this.hasButtonNavigation = false
          }else{
            this.hasDropdown = false
            this.hasButtonNavigation = true
          }      
        }
        this.dropdownOption = nav.map((item) => item.title);
        break;

      default:
        break;
    }
  }

  private initDropdownData(graph: GraphMenu) {
    const nav = graph[this.currentDropdownIndex].data;
    if (!Array.isArray(nav)) {
      this.createTimeTabs(nav as GraphNavigation);
      if(this?.dataForChart?.type !== 'RFC'){
        this.setDefaultTimeTabIndex();
      }
      this.navData = [nav.month, nav.qtd, nav.ytd];
    } else {
      this.navData = [nav];
    }
  }

  private createTimeTabs(navigation: GraphNavigation): void {
    this.timeTabs = Object.entries(TimePeriods).map(([key, value]) => ({
      key: key,
      name: value,
      disabled: !navigation[key as keyof GraphNavigation],
    }));
  }

  /**
   * Sets the default index of the time tab.
   */
  public setDefaultTimeTabIndex(): void {
    if (
      this.timeTabs[this.currentTab] ||
      this.timeTabs[this.currentTab].disabled
    ) {
      const defaultTimeTabIndex = this.timeTabs.findIndex(
        (timeTab) => timeTab && !timeTab.disabled
      );
      if (defaultTimeTabIndex == -1) {
        console.error(`All time tabs are disabled`);
      } else {
        this.currentTab = defaultTimeTabIndex;
      }
    }
  }

  public changeTab(index: number, fromClick: boolean = false): void {
    if (
      this.timeTabs[index] &&
      !this.timeTabs[index].disabled &&
      index < this.timeTabs.length
    ) {
      if (fromClick) {
        const currentSlideMap = ['last year', 'no comparasion', 'budget'];
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'fireEvent',
          event_name: 'element_click',
          division: this?.currentPath?.div || 'ICL',
          chart_name: this?.currentPath?.graph || 'StocksPanel',
          type: 'engagement',
          section: 'chart',
          chart_type: chartTypeMap.get(
            this?.dataForChart?.type ?? GraphType.Sales
          ),
          chart_state:
            this?.dropdownOption?.[this?.currentDropdownIndex] || undefined,
          time_range:
            this?.timeTabs?.[this?.currentTab || 0]?.name || undefined,
          comparison_scope:
            this?.currentSlide !== undefined
              ? currentSlideMap?.[this?.currentSlide]
              : undefined,
          sub_business_unit:
            this?.dataForChart?.subMenu?.[this.currentBUIndex]?.title ||
            undefined,
          purpose: 'changing time range',
          description: this?.timeTabs?.[index || 0]?.name || undefined,
        });
      }
      this.currentTab = index;
      this.updateNavState.emit(this.getCurrentNavState());
    } else {
      console.error(`Selected tab not allowed`);
    }
  }

  /**
   * Adjusts slider config based on the number of available slides.
   */
  public sliderCountHandle(count: number): any {
    if (count > 1) {
      this.sliderConfig.dots = true;
    } else {
      this.sliderConfig.dots = false;
    }

    if (this.currentSlide !== undefined) {
      this.sliderConfig.initialSlide = this.currentSlide;
    } else {
      if (count === 3) {
        this.sliderConfig.initialSlide = this.currentSlide = 1;
      } else {
        this.sliderConfig.initialSlide = this.currentSlide = 0;
      }
    }
    return this.sliderConfig;
  }

  public trackByFunc(index: number, item: any): number {
    return index;
  }

  public toggleDropdown(): void {
    const currentSlideMap = ['last year', 'no comparasion', 'budget'];
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'fireEvent',
      event_name: 'element_click',
      division: this?.currentPath?.div || 'ICL',
      chart_name: this?.currentPath?.graph || 'StocksPanel',
      type: 'engagement',
      section: 'chart',
      chart_type: chartTypeMap.get(this?.dataForChart?.type ?? GraphType.Sales),
      chart_state:
        this?.dropdownOption?.[this?.currentDropdownIndex] || undefined,
      time_range: this?.timeTabs?.[this?.currentTab || 0]?.name || undefined,
      comparison_scope:
        this?.currentSlide !== undefined
          ? currentSlideMap?.[this?.currentSlide]
          : undefined,
      sub_business_unit:
        this?.dataForChart?.subMenu?.[this.currentBUIndex]?.title || undefined,
      purpose: this.isDropdownOpen
        ? 'closing drop down menu'
        : 'opening drop down menu',
    });
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  public toggleCategoryNav(): void {
    const currentSlideMap = ['last year', 'no comparasion', 'budget'];
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'fireEvent',
      event_name: 'element_click',
      division: this?.currentPath?.div || 'ICL',
      chart_name: this?.currentPath?.graph || 'StocksPanel',
      type: 'engagement',
      section: 'chart',
      chart_type: chartTypeMap.get(this?.dataForChart?.type ?? GraphType.Sales),
      chart_state:
        this?.dropdownOption?.[this?.currentDropdownIndex] || undefined,
      time_range: this?.timeTabs?.[this?.currentTab || 0]?.name || undefined,
      comparison_scope:
        this?.currentSlide !== undefined
          ? currentSlideMap?.[this?.currentSlide]
          : undefined,
      sub_business_unit:
        this?.dataForChart?.subMenu?.[this.currentBUIndex]?.title || undefined,
      purpose: this.isCategoryNavOpen
        ? 'closing drawer'
        : 'opening drawer',
    });
    this.isCategoryNavOpen = !this.isCategoryNavOpen;
  }

  public changeDataByDropdown(dropdownIndex: number, title: String): void {
    const currentSlideMap = ['last year', 'no comparasion', 'budget'];
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'fireEvent',
      event_name: 'element_click',
      division: this?.currentPath?.div || 'ICL',
      chart_name: this?.currentPath?.graph || 'StocksPanel',
      type: 'engagement',
      section: 'chart',
      chart_type: chartTypeMap.get(this?.dataForChart?.type ?? GraphType.Sales),
      chart_state:
        this?.dropdownOption?.[this?.currentDropdownIndex] || undefined,
      time_range: this?.timeTabs?.[this?.currentTab || 0]?.name || undefined,
      comparison_scope:
        this?.currentSlide !== undefined
          ? currentSlideMap?.[this?.currentSlide]
          : undefined,
      sub_business_unit:
        this?.dataForChart?.subMenu?.[this.currentBUIndex]?.title || undefined,
      purpose: 'choosing dropdown option',
      description: title,
    });
    this.isDropdownOpen = false;
    this.currentDropdownIndex = dropdownIndex;
    this.initCurrentData();
    this.updateNavState.emit(this.getCurrentNavState());
  }

  public changeDataByDrawer(dropdownIndex: number, title: String): void {
    const currentSlideMap = ['last year', 'no comparasion', 'budget'];
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'fireEvent',
      event_name: 'element_click',
      division: this?.currentPath?.div || 'ICL',
      chart_name: this?.currentPath?.graph || 'StocksPanel',
      type: 'engagement',
      section: 'chart',
      chart_type: chartTypeMap.get(this?.dataForChart?.type ?? GraphType.Sales),
      chart_state:
        this?.dropdownOption?.[this?.currentDropdownIndex] || undefined,
      time_range: this?.timeTabs?.[this?.currentTab || 0]?.name || undefined,
      comparison_scope:
        this?.currentSlide !== undefined
          ? currentSlideMap?.[this?.currentSlide]
          : undefined,
      sub_business_unit:
        this?.dataForChart?.subMenu?.[this.currentBUIndex]?.title || undefined,
      purpose: 'choosing drawer option',
      description: title,
    });
    this.isCategoryNavOpen = false;
    this.currentDropdownIndex = dropdownIndex;
    this.initCurrentData();
    this.clearSearch()
    this.updateNavState.emit(this.getCurrentNavState());
  }
  public generateDrawerMenu(navData:any){
    const result: any = {}
    navData.forEach((item:any,index:any)=>{
      if(!result[item.category]){
        result[item.category]=[]
      }
      result[item.category].push({name:item.title,index:index})
    })
    return Object.keys(result).map(function (key) { return {name:key,data:result[key]} });
  }
  public filterDrawerMenuByString(event:any){
    this.stringToFilterBy = event.target.value;
    const tmpData = JSON.parse(JSON.stringify(this.drawerMenuData))
    const result:any=[]
    tmpData.forEach((item:any)=>{
      const tempData = item.data.filter((buttonData:any)=>{
        if(typeof buttonData.name === 'string'){
          if(buttonData.name.toUpperCase().includes(this.stringToFilterBy.toUpperCase())){
            return true
          }
        }
        return false
      })
      if(tempData.length){
        result.push({name:item.name,data:tempData})
      }
    })
    this.filteredDrawerMenuData = result;
  }
  public clearSearch(){
    this.stringToFilterBy = "";
    this.searchInput.nativeElement.value = "";
    this.filteredDrawerMenuData = this.drawerMenuData;
  }
  public afterChange(obj: any): void {
    const currentSlideMap = ['last year', 'no comparasion', 'budget'];
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'fireEvent',
      event_name: 'element_click',
      division: this?.currentPath?.div || 'ICL',
      chart_name: this?.currentPath?.graph || 'StocksPanel',
      type: 'engagement',
      section: 'chart',
      chart_type: chartTypeMap.get(this?.dataForChart?.type ?? GraphType.Sales),
      chart_state:
        this?.dropdownOption?.[this?.currentDropdownIndex] || undefined,
      time_range: this?.timeTabs?.[this?.currentTab || 0]?.name || undefined,
      comparison_scope:
        this?.currentSlide !== undefined
          ? currentSlideMap?.[this?.currentSlide]
          : undefined,
      sub_business_unit:
        this?.dataForChart?.subMenu?.[this.currentBUIndex]?.title || undefined,
      purpose: 'changing comparison scope',
      description: currentSlideMap?.[obj.currentSlide],
    });
    this.currentSlide = obj.currentSlide;
    this.updateNavState.emit(this.getCurrentNavState());
  }

  public getBusinessUnits(): NavigationItem<RTSparkChart>[] {
    return this.dataForChart.subMenu ?? [];
  }

  public getSubBusinessUnits(): SubNavigationItem<RTSparkChart>[] {
    if (this.dataForChart.subMenu && this.currentBUIndex !== -1) {
      return this.dataForChart.subMenu[this.currentBUIndex].data ?? [];
    }
    return [];
  }

  public onChangeUnit(index: number): void {
    this.currentBUIndex = index;
    this.updateNavState.emit(this.getCurrentNavState());
    this.cdr.detectChanges();
  }

  public onChangeSubUnit(index: number): void {
    this.currentSBUIndex = index;
    this.updateNavState.emit(this.getCurrentNavState());
    this.cdr.detectChanges();
  }

  public emitNavState(): void {
    this.updateNavState.emit(this.getCurrentNavState());
    this.cdr.detectChanges();
  }
}
