<app-territory-slide 
*ngIf="isTerritory"
[navState]="navState" 
(selectedContinentalChange)="changeSelectedCountry($event)"
[selectedContinental]="selectedCountry"
[currentSbuTitle]="isTerritory ? data.graph[territoryObjectMap[selectedCountry]].data[indexUnit || 0]?.title : data.graph.data[indexUnit || 0]?.title"
>
</app-territory-slide>
<app-line-bar-graph
[lineGraph]="data.graph.data[indexUnit || 0]?.lineGraph"
[selectedColumnIndex]="currentSelectedColumnIndex"
>  
</app-line-bar-graph>

<app-vertical-bar-graph
  [selectedColumnIndex]="currentSelectedColumnIndex"
  [graph]="isTerritory ?data.graph[territoryObjectMap[selectedCountry]].data[indexUnit || 0].graph : data.graph.data[indexUnit || 0].graph"
  (onBarSelect)="onSelectGarphValue($event)"
  (onUserBarSelect)="onUserBarSelect($event)"
  [type]="getBarType(data)"
  [isLoading]="isLoading"
  [isPercentage]="isPercentage"
  *ngIf="!isStackedArea"
  [navState]="navState"
  [currentSbuTitle]="isTerritory ?data.graph[territoryObjectMap[selectedCountry]].data[indexUnit || 0]?.title : data.graph.data[indexUnit || 0]?.title"
>
</app-vertical-bar-graph>

<app-stacked-area-chart
  [colData]="data.graph.colData"
  [selectedColumnIndex]="currentSelectedColumnIndex"
  [graph]="data.graph.data[indexUnit || 0].graph"
  [isLoading]="isLoading"
  *ngIf="isStackedArea"
  (onBarSelect)="onSelectGarphValue($event)"
  (onUserBarSelect)="onUserBarSelect($event)"
  [navState]="navState"
  [currentSbuTitle]="data.graph.data[indexUnit || 0]?.title"
>
</app-stacked-area-chart>

<app-battery-graph
  [changeBatteryIndex]="changeBatteryIndex"
  [isTerritory]="isTerritory"
  [isMainGraph]="!isTerritory && !!data.graph.isMainGraph"
  [columnIndex]="currentSelectedColumnIndex"
  [navState]="navState"
  [isLoading]="isLoading"
  [batteriesArray]="isTerritory ?data.graph[territoryObjectMap[selectedCountry]].data[indexUnit || 0].battery: []"
  [selectedBatteryIndex]="selectedBatteryIndex"
  [batteries]="isTerritory ? data.graph[territoryObjectMap[selectedCountry]].data[indexUnit || 0].battery[selectedBatteryIndex].data[currentSelectedColumnIndex] : data.graph.data[indexUnit || 0].battery[currentSelectedColumnIndex]"
  [currentSbuTitle]="isTerritory ? data.graph[territoryObjectMap[selectedCountry]].data[indexUnit || 0]?.title : data.graph.data[indexUnit || 0]?.title"
  [type]="isTerritory ? 'map' : isStackedArea ? 'area chart' : 'bar chart'"
>
</app-battery-graph>
