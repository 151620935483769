import { Component, OnInit, Input } from '@angular/core';
import { EChartsOption } from 'echarts';
import * as echarts from 'echarts';
import { AreaGraph, AreaGraphView } from 'src/app/model/vertical-views';

const CANVAS_HEIGHT_PX = 230;
const PADDING_BOTTOM_CANVAS_PX = 25;
const PADDING_TOP_CANVAS_PX = 45;
const COMPUTED_CANVAS_HEIGHT_PX =
  CANVAS_HEIGHT_PX - PADDING_BOTTOM_CANVAS_PX - PADDING_TOP_CANVAS_PX;
const MINCHANGE_PX_OFFSET = 32;

@Component({
  selector: 'app-stacked-area-chart',
  templateUrl: './stacked-area-chart.component.html',
  styleUrls: ['./stacked-area-chart.component.scss'],
})
export class StackedAreaChartComponent implements OnInit {
  @Input() isLoading = true;
  @Input() selectedColumnIndex?: number;
  @Input() set graph(value: AreaGraph) {
    this._graph = value;
    this.initGraphOption();
  }
  @Input() colData: any[] = [];

  public myChart: any;
  public options: EChartsOption = {};
  public mergeOptions: EChartsOption = {};

  private _graph: AreaGraph = {} as AreaGraph;
  private baseOptions: any = {
    textStyle: {
      color: '#768B95',
      fontSize: 10,
      fontFamily: 'InterRegular',
    },
    legend: {
      show: false, 
      data: [],
    },
    grid: {
      left: '3%',  
      right: '15%',  
      top: '3%',    
      bottom: '3%', 
      containLabel: true,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: [],
        axisLine: {
          lineStyle: {
            color: '#294E67',
          }
        },
        axisTick: {
          interval: 0,
          height: 6
        },
      },
      {
        type: 'category',
        boundaryGap: false,
        axisLine: {
          show: false,
          onZero: false,
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          margin: 0,
          color: '#768B95',
          fontSize: 10,
          fontFamily: 'InterMedium',
          borderColor: '#1A4F69',
          align: 'center',
          verticalAlign: 'center',
          borderWidth: 1,
          borderType: 'solid',
          borderRadius: 4,
          backgroundColor: '#002949',
          padding: [1, 4, 2, 4],
          formatter: function (value: any) {
            return value && value;
          }
        },
        position: 'bottom',
        offset: 30,
        data: []
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        max: 100, 
        splitLine: {
          show: false, 
        },
        axisTick: {
          show: false, 
        },
        axisLabel: {
          formatter: (value: number) => {
            if (value === 0 || value === 100) {
              return `${value}%`; 
            }
            return ''; 
          },
          fontSize: 10, 
        },
      },
    ],
    series: null,
  };
  
  

  get graph() {
    return this._graph;
  }
  constructor() {}

  ngOnInit(): void {
    this.initGraphOption();
  }

  public onChartInit(ec: any): void {
    this.myChart = ec;
    this.initChartEvents(ec);
  }

  private initChartEvents(chartInstance: any): void {
    chartInstance.getZr().on('click', (params: any) => {
      const pointInPixel = [params.offsetX, params.offsetY];
      const pointInGrid = chartInstance.convertFromPixel('grid', pointInPixel);
    });
  }

  private initGraphOption(): void {
    this.baseOptions.xAxis[0].data = this.colData.map(item => item.colName)
    this.baseOptions.xAxis[1].data = this.colData.map(item => {
      if (item.isPreliminary) {
        return { value: 'P' };
      } else {
        return { value: '-', textStyle: { opacity: 0 } };
      }
    });
    this.baseOptions.legend.data = this.generateLegend(this.graph.data);
    this.baseOptions.series = this.mapCurrentData(this.graph.data);
    this.options = { ...this.baseOptions };
  }

  private mapCurrentData(graphData: any[]): any[] {
    return graphData.map((item, index) => this.mapCurrentDataItem(item, index));
  }

  private arrayOfColors = [
    '#918EF4',
    '#EB88DB',
    '#E3E5BC',
    '#00BBF9',
    '#00F5D4',
  ];

  private mapCurrentDataItem(item: any, index: any): any {
    const lastValue = item.data[item.data.length - 1]; // Get the last data point value
    const categoryName = item.colName; // Use the category name (e.g., 'ISR')
  
    // Calculate the cumulative y-position for stacking
    let stackPosition = 0;
    for (let i = 0; i < index; i++) {
      // Sum all previous series' last values to create the stacked position
      stackPosition += this.graph.data[i].data[item.data.length - 1] || 0;
    }
  
    return {
      name: categoryName,
      type: 'line',
      stack: 'Total',
      xAxisIndex: 0,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: this.arrayOfColors[index]
          },
          {
            offset: 1,
            color: 'transparent'
          }
        ]),
        opacity: '0.5',
      },
      lineStyle: {
        color: this.arrayOfColors[index],
        width: 2,
      },
      symbol: 'circle',
      symbolSize: 0,
      itemStyle: {
        color: this.arrayOfColors[index],
      },
      data: item.data,
      markPoint: {
        data: [
          {
            name: categoryName,
            coord: [item.data.length - 1, stackPosition + lastValue], // Stack based on the previous series' positions
            label: {
              show: true,
              position: 'end', // Position the label at the end of the series
              formatter: `${categoryName}: ${lastValue}%`, // Show category and percentage value
              color: this.arrayOfColors[index], // Match text color with the line color
              fontSize: 11,
              fontWeight: 500,
              offset: [5, 0], // Margin to the right of the mark point, no vertical offset as we calculate it dynamically
            },
            symbol: 'circle', // Symbol for the point (optional)
            symbolSize: 0,
            itemStyle: {
              color: this.arrayOfColors[index], // Match symbol color with the line color
            },
            tooltip: null, // Disable tooltip
            emphasis: {
              itemStyle: {
                color: this.arrayOfColors[index], // Keep color constant on hover
              },
              label: {
                color: this.arrayOfColors[index], // Keep label color constant
              }
            }
          },
        ],
      },
    };
  }
  
   
  private generateLegend(graphData: any[]): String[] {
    return graphData.map((item) => item.colName);
  }
}
