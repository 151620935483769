<app-vertical-bar-graph
  [selectedColumnIndex]="currentSelectedColumnIndex"
  [graph]="data.graph.data[indexUnit || 0].graph"
  (onBarSelect)="onSelectGarphValue($event)"
  (onUserBarSelect)="onUserBarSelect($event)"
  [type]="getBarType(data)"
  [isLoading]="isLoading"
  [isPercentage]="isPercentage"
  *ngIf="!isStackedArea"
>
</app-vertical-bar-graph>

<app-stacked-area-chart
  [colData]="data.graph.colData"
  [selectedColumnIndex]="currentSelectedColumnIndex"
  [graph]="data.graph.data[indexUnit || 0].graph"
  [isLoading]="isLoading"
  *ngIf="isStackedArea"
>
</app-stacked-area-chart>

<app-battery-graph
  [isMainGraph]="!!data.graph.isMainGraph"
  [columnIndex]="currentSelectedColumnIndex"
  [navState]="navState"
  [isLoading]="isLoading"
  [batteries]="data.graph.data[indexUnit || 0].battery[currentSelectedColumnIndex]"
>
</app-battery-graph>
