import { Component, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Subject, takeUntil } from 'rxjs';
import { GraphType, chartTypeMap } from 'src/app/model/generic-graph';

/**
 * @description
 * This component acts as a container for a world map graph and displays the data about each continent.
 * On clicking a continent, its data is displayed on the screen.
 */
@Component({
  selector: 'app-territory-slide',
  templateUrl: './territory-slide.component.html',
  styleUrls: ['./territory-slide.component.scss'],
})
export class TerritorySlideComponent implements OnInit, OnDestroy {
  public currentPath: any = {};
  public countryColorAndFocus: string = '#024266';
  public countryDataIndex: number = 0;
  @Input() public selectedContinental: any = 0;
  @Input() navState: any = null;
  @Input() currentSbuTitle: any = null;
  public dataIndex: number = this.selectedContinental || 0;
  
  private _destroyed$ = new Subject();
  
  private continentsTitleArray: string[] = [
    "North America",
    "South America",
    "Europe",
    "Asia",
    "Rest of The World",
    "All"
  ];
  
  @ViewChild('mapContainer') mapContainer: any;
  @Output() public selectedContinentalChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.currentPath.pipe(takeUntil(this._destroyed$)).subscribe(params => {
      this.currentPath = params;
    });
    setTimeout(() => {
      if (this.selectedContinental) {
        this.setSelectedContinental(null, this.selectedContinental);
      } else {
        this.setSelectedContinental(null, this.dataIndex);
      }
    });
  }

  public setSelectedContinental(element: any, index: number): void {
    this.dataIndex = index;
    const container = this.mapContainer?.nativeElement;
    if (!container) {
      return;
    }
    
    const restOfTheWorldIndex = 4;
    const allIndex = 5;
    
    // Remove the 'focused' class from all country areas
    const countryElements = container.querySelectorAll('.country-area') as NodeListOf<HTMLElement>;
    countryElements.forEach((countryElement: HTMLElement) => {
      countryElement.classList.remove('focused');
    });
    
    // If an element was clicked, push an event to the dataLayer
    if (element) {
      const timeRangeMap = ['M', 'QTD', 'YTD'];
      const currentSlideMap = ['last year', 'no comparasion', 'budget'];
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'fireEvent',
        'event_name': 'element_click',
        'division': this?.currentPath?.div || 'ICL',
        'chart_name': this?.navState?.graph,
        'type': 'engagement',
        'section': 'chart',
        'chart_type': chartTypeMap.get(GraphType.TERRITORY),
        'chart_state': this.navState.dropdown,
        'time_range': timeRangeMap[this?.navState?.nav || 0],
        'comparison_scope': currentSlideMap[this?.navState?.slide !== undefined ? this?.navState?.slide : 1], 
        'sub_business_unit': this.currentSbuTitle,
        'purpose': index === allIndex ? 'reseting chart' : 'displaying region data',
        'description': `${this.continentsTitleArray[index]} via map`,
      });
    }
    
    const elements = container.querySelectorAll('.map-container > div') as NodeListOf<HTMLElement>;
    if (elements.length === 0) {
      return;
    }
    if (index === allIndex) {
    } else if (index === restOfTheWorldIndex) {
      if (elements[4]) {
        const elems = elements[4].querySelectorAll('.country-area') as NodeListOf<HTMLElement>;
        elems.forEach(el => el.classList.add('focused'));
      }
      if (elements[5]) {
        const elems = elements[5].querySelectorAll('.country-area') as NodeListOf<HTMLElement>;
        elems.forEach(el => el.classList.add('focused'));
      }
    } else {
      if (elements[index]) {
        const elems = elements[index].querySelectorAll('.country-area') as NodeListOf<HTMLElement>;
        elems.forEach(el => el.classList.add('focused'));
      }
    }
    
    this.countryDataIndex = 0;
    this.selectedContinentalChange.emit(index);
  }

  ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }
}
