
<ng-container *ngIf="!isLoading">
    <div class="buisness-units-tabs" *ngIf="isTerritory" >
        <button *ngFor="let unit of batteriesArray; let index = index"
        class="unit-tab unit-tab-center" [ngClass]="{'active': index === selectedBatteryIndex}"
        (click)="changeBatteryIndex(index)">
            <div class="unit-tab-titles">
                <span class="title">{{unit.title}}</span>
            </div>
        </button>
    </div>
  <div class="batteryContainer" *ngIf="batteries">
    <div [ngClass]=" isMainGraph ? 'column-name-row' : 'column-name-row hidePointer' " >
      <span class="title" ></span>
      <span class="column" *ngFor="let coloumnTitle of batteries.colNames">{{coloumnTitle}}</span>
      <span class="value"></span>
      <div *ngIf="isMainGraph" class="pointer"></div>
    </div>
    <div *ngFor="let item of batteries.columns; trackBy:trackByFn" rel="button">
      <app-battery [item]="item" [isMainGraph]="isMainGraph" [sbuBattery]="true" [isTwoColumns]="isTwoColumns" (click)="onNavigateToGraph(item)" [columnNames]="batteries.colNames"></app-battery>
    </div>
  </div>
</ng-container>


<ng-container *ngIf="isLoading">
  <div class="batteryContainer loader">
      <app-loader></app-loader>
  </div>
</ng-container>
